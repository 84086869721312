@use "sass:math";

$dark-purple: #0e0238;
$flat-purple: #4c2990;
$gray: #f2f2f2;
$gray-dark: mix($dark-purple, white, 12%);
$gray-darken: mix($dark-purple, white, 24%);
$purple: #8d52e8;
$pink: #d6116d;
$salmon: #ff7171;
$black: #333333;
$red: #ff3737;
$green: #4bb14b;
$blue: #26a2f5b9;

$darkenScale: 5%;
$gradient: linear-gradient(35deg, $purple -60%, $dark-purple);
$gradient-dark: linear-gradient(
    35deg,
    darken($purple, $darkenScale) -60%,
    darken($dark-purple, $darkenScale)
);
$gradient2: linear-gradient(30deg, $pink 10%, $salmon);
$gradient2-dark: linear-gradient(
    30deg,
    darken($pink, $darkenScale) 10%,
    darken($salmon, $darkenScale)
);

$font: "Maven Pro", sans-serif;

$extratiny: 12px;
$tiny: 14px;
$small: 16px;
$supersmall: 18px;
$medium: 24px;
$big: 36px;
$superbig: 42px;

$side-menu: 250px;
$margin: 30px;
$border-radius: 5px;

body {
    margin: 0;
    padding: 0;
    font-family: $font;
    overflow-x: hidden;
}

@mixin panel() {
    background-color: white;
    border-radius: $border-radius;
    padding: $margin;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(black, 0.1);
}

@mixin panel2() {
    background: $gradient;
    border-radius: $border-radius;
    padding: $margin;
    box-shadow: 0 0 20px rgba($dark-purple, 0.2);
}

@mixin button() {
    background: $gradient2;
    margin: 0;
    border-radius: $border-radius;
    color: white;
    font-size: $small;
    padding: math.div($margin, 1.5);
    width: 100%;

    &.active {
        cursor: pointer;

        &:hover,
        &:focus {
            background: $gradient2-dark;
        }
    }
}

@mixin input-gray() {
    display: block;
    border: none;
    outline: none;
    margin-left: math.div($margin, 1.5);
    padding: $margin * 0.5;
    font-size: $small;
    background-color: $gray;
    border-radius: $border-radius;
}

@mixin button-primary() {
    background-image: linear-gradient(30deg, #d6116d 10%, #ff7171);
    border: none;
    color: white;

    &:hover:not(:disabled) {
        background-image: linear-gradient(30deg, darken(#d6116d, 5%) 10%, darken(#ff7171, 5%));
    }
}

@mixin button-global() {
    border-radius: $border-radius;
    border-radius: 5px;
    border: 1px solid $gray-dark;
    box-sizing: border-box;
    color: $black;
    height: 30px;
    outline: none;
    padding: $margin * 0.25 $margin * 0.5;
    text-transform: uppercase;
    transition: background-image 0.3s ease-in;
    background-image: linear-gradient(to bottom, lighten($gray, 1%), darken($gray, 1%));

    &:hover:not(:disabled) {
        background-image: linear-gradient(to bottom, darken($gray, 1%), darken($gray, 2%));
    }
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }

    img {
        height: 15px;
        width: 15px;
    }
}

@mixin noSelect() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin cmsInput() {
    background-color: #ffffff;
    border-radius: $border-radius;
    border: 1px solid $gray-dark;
    box-sizing: border-box;
    color: #000000;
    font-family: $font;
    height: 30px;
    letter-spacing: -1.03px;
    outline: none;
    padding: $margin * 0.25 $margin * 0.5;

    &::placeholder {
        font-family: $font;
        letter-spacing: -1.03px;
        color: #8c82ad;
    }

    &:disabled {
        background-color: $gray;
    }
}

@mixin flatSelect() {
    height: 40px !important;
    box-shadow: none !important;
    border: 1px solid #dddddd !important;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.86px;
    color: #000000;
    width: 100%;
}

@mixin h1() {
    font-size: 35px;
    letter-spacing: -2px;
    color: $flat-purple;
    margin: 0;
    font-weight: 300;
}

.button {
    margin-right: 0 !important;
    position: relative;
}

.flat {
    -webkit-font-smoothing: inherit;
    background-color: white;
    background-image: initial;
    border: solid 1px #dddddd;
    color: black;
    font-family: inherit;
    font-weight: 500;
    letter-spacing: -0.86px;
    text-transform: initial;
    padding: 0 18px;
    height: 40px;
    font-size: 15px;
}

.tiny {
    max-height: 30px;
}

.marginRight {
    margin-right: math.div($margin, 3);
}

.marginLeft {
    margin-left: math.div($margin, 3) !important;
}

.marginTop {
    margin-top: math.div($margin, 3) * 2 !important;
}

$addColor: $flat-purple;
$substractColor: #e2146d;
$gradient: linear-gradient(to right, #e2146d, #f85f70);

.circleButton {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .circle {
        border-radius: 50%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            fill: white;
        }
    }

    .children {
        margin-left: math.div($margin, 3);
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: -0.5px;
    }
}

.circleBorder {
    .circle {
        background: white;
        border: 1px solid #c9c9c9;
    }
}

.add {
    .circle {
        background: $addColor;
    }

    .children {
        color: $addColor;
    }
}

.substract {
    .circle {
        background: $gradient;

        svg {
            transform: rotate(-45deg);
        }
    }

    .children {
        color: $substractColor;
    }
}

.greyIcon {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-left: auto;
    flex-shrink: 0;

    svg {
        fill: #888888;
        width: 100%;
        height: 100%;
    }

    .children {
        color: #888888;
    }
}

@mixin circleSize($size) {
    .circle {
        width: $size;
        height: $size;

        svg {
            width: floor(math.div($size, 3));
            height: floor(math.div($size, 3));
        }
    }

    .children {
        font-size: $size * 0.5;
    }
}

.tiny {
    $size: 20px;
    @include circleSize($size);
}

.small {
    $size: 30px;
    @include circleSize($size);
}

.medium {
    $size: 40px;
    @include circleSize($size);
}

.green {
    background: #46d440;
    border-color: #46d440;
    color: white;

    &:hover:not(:disabled) {
        background-image: none !important;
        background-color: darken(#46d440, 7%) !important;
    }
}

.purple {
    background: #4c2990 !important;
    color: white;

    &:hover:not(:disabled) {
        background: darken(#4c2990, 7%) !important;
    }

    &:disabled {
        opacity: 0.3;
    }
}

.darkPurple {
    background: #281352 !important;
    color: white;

    &:hover:not(:disabled) {
        background: darken(#281352, 7%) !important;
    }
}

.pinkGradient {
    background: $gradient2;
    color: white;

    &:hover:not(:disabled) {
        background: $gradient2-dark;
    }
}
