@use "sass:math";

$dark-purple: #0e0238;
$flat-purple: #4c2990;
$gray: #f2f2f2;
$gray-dark: mix($dark-purple, white, 12%);
$gray-darken: mix($dark-purple, white, 24%);
$purple: #8d52e8;
$pink: #d6116d;
$salmon: #ff7171;
$black: #333333;
$red: #ff3737;
$green: #4bb14b;
$blue: #26a2f5b9;

$darkenScale: 5%;
$gradient: linear-gradient(35deg, $purple -60%, $dark-purple);
$gradient-dark: linear-gradient(
    35deg,
    darken($purple, $darkenScale) -60%,
    darken($dark-purple, $darkenScale)
);
$gradient2: linear-gradient(30deg, $pink 10%, $salmon);
$gradient2-dark: linear-gradient(
    30deg,
    darken($pink, $darkenScale) 10%,
    darken($salmon, $darkenScale)
);

$font: "Maven Pro", sans-serif;

$extratiny: 12px;
$tiny: 14px;
$small: 16px;
$supersmall: 18px;
$medium: 24px;
$big: 36px;
$superbig: 42px;

$side-menu: 250px;
$margin: 30px;
$border-radius: 5px;

body {
    margin: 0;
    padding: 0;
    font-family: $font;
    overflow-x: hidden;
}

@mixin panel() {
    background-color: white;
    border-radius: $border-radius;
    padding: $margin;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(black, 0.1);
}

@mixin panel2() {
    background: $gradient;
    border-radius: $border-radius;
    padding: $margin;
    box-shadow: 0 0 20px rgba($dark-purple, 0.2);
}

@mixin button() {
    background: $gradient2;
    margin: 0;
    border-radius: $border-radius;
    color: white;
    font-size: $small;
    padding: math.div($margin, 1.5);
    width: 100%;

    &.active {
        cursor: pointer;

        &:hover,
        &:focus {
            background: $gradient2-dark;
        }
    }
}

@mixin input-gray() {
    display: block;
    border: none;
    outline: none;
    margin-left: math.div($margin, 1.5);
    padding: $margin * 0.5;
    font-size: $small;
    background-color: $gray;
    border-radius: $border-radius;
}

@mixin button-primary() {
    background-image: linear-gradient(30deg, #d6116d 10%, #ff7171);
    border: none;
    color: white;

    &:hover:not(:disabled) {
        background-image: linear-gradient(30deg, darken(#d6116d, 5%) 10%, darken(#ff7171, 5%));
    }
}

@mixin button-global() {
    border-radius: $border-radius;
    border-radius: 5px;
    border: 1px solid $gray-dark;
    box-sizing: border-box;
    color: $black;
    height: 30px;
    outline: none;
    padding: $margin * 0.25 $margin * 0.5;
    text-transform: uppercase;
    transition: background-image 0.3s ease-in;
    background-image: linear-gradient(to bottom, lighten($gray, 1%), darken($gray, 1%));

    &:hover:not(:disabled) {
        background-image: linear-gradient(to bottom, darken($gray, 1%), darken($gray, 2%));
    }
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }

    img {
        height: 15px;
        width: 15px;
    }
}

@mixin noSelect() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin cmsInput() {
    background-color: #ffffff;
    border-radius: $border-radius;
    border: 1px solid $gray-dark;
    box-sizing: border-box;
    color: #000000;
    font-family: $font;
    height: 30px;
    letter-spacing: -1.03px;
    outline: none;
    padding: $margin * 0.25 $margin * 0.5;

    &::placeholder {
        font-family: $font;
        letter-spacing: -1.03px;
        color: #8c82ad;
    }

    &:disabled {
        background-color: $gray;
    }
}

@mixin flatSelect() {
    height: 40px !important;
    box-shadow: none !important;
    border: 1px solid #dddddd !important;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.86px;
    color: #000000;
    width: 100%;
}

@mixin h1() {
    font-size: 35px;
    letter-spacing: -2px;
    color: $flat-purple;
    margin: 0;
    font-weight: 300;
}

.menu {
    height: 60px;
    width: calc(100% - 250px);
    position: fixed;
    top: 0;
    left: 250px;
    background-color: white;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    z-index: 1000;
    background: $gradient2;
    color: rgba(255, 255, 255, 0.75);

    .backlink {
        display: flex;
        align-items: center;
        margin-left: math.div($margin, 1.5);
        font-size: $small;
        cursor: pointer;
        letter-spacing: 0.5px;
        color: rgba(255, 255, 255, 0.75);

        &:hover {
            text-decoration: underline;
        }

        .arrow {
            height: 21px;
            opacity: 0.75;
            margin-right: 8px;
        }
    }

    h2 {
        margin: 0 0 0 $margin * 0.5;
        color: rgba(255, 255, 255, 0.75);
        font-size: $supersmall;
        font-weight: 500;
        letter-spacing: 0.5px;
    }

    .validationStatus {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: math.div($margin, 1.5);
        position: relative;
        padding: 4px 6px;

        &.invalid {
            cursor: pointer;

            &:hover {
                background-color: rgba(white, 0.15);
                border-radius: 15px;
            }
        }

        .diode {
            width: 12px;
            height: 12px;
            border-radius: 100%;
            position: relative;

            &.valid {
                background-color: $green;
            }

            &.invalid {
                background-color: $red;
            }

            .border {
                width: 24px;
                height: 24px;
                border-radius: 100%;
                background-color: rgba(white, 0.2);
                position: absolute;
                left: -6px;
                top: -6px;
                z-index: -1;
            }
        }

        .status {
            margin-left: $margin * 0.5;
            font-size: $small;
            letter-spacing: 1px;
        }
    }
}
