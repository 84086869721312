@use "sass:math";

$dark-purple: #0e0238;
$flat-purple: #4c2990;
$gray: #f2f2f2;
$gray-dark: mix($dark-purple, white, 12%);
$gray-darken: mix($dark-purple, white, 24%);
$purple: #8d52e8;
$pink: #d6116d;
$salmon: #ff7171;
$black: #333333;
$red: #ff3737;
$green: #4bb14b;
$blue: #26a2f5b9;

$darkenScale: 5%;
$gradient: linear-gradient(35deg, $purple -60%, $dark-purple);
$gradient-dark: linear-gradient(
    35deg,
    darken($purple, $darkenScale) -60%,
    darken($dark-purple, $darkenScale)
);
$gradient2: linear-gradient(30deg, $pink 10%, $salmon);
$gradient2-dark: linear-gradient(
    30deg,
    darken($pink, $darkenScale) 10%,
    darken($salmon, $darkenScale)
);

$font: "Maven Pro", sans-serif;

$extratiny: 12px;
$tiny: 14px;
$small: 16px;
$supersmall: 18px;
$medium: 24px;
$big: 36px;
$superbig: 42px;

$side-menu: 250px;
$margin: 30px;
$border-radius: 5px;

body {
    margin: 0;
    padding: 0;
    font-family: $font;
    overflow-x: hidden;
}

@mixin panel() {
    background-color: white;
    border-radius: $border-radius;
    padding: $margin;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(black, 0.1);
}

@mixin panel2() {
    background: $gradient;
    border-radius: $border-radius;
    padding: $margin;
    box-shadow: 0 0 20px rgba($dark-purple, 0.2);
}

@mixin button() {
    background: $gradient2;
    margin: 0;
    border-radius: $border-radius;
    color: white;
    font-size: $small;
    padding: math.div($margin, 1.5);
    width: 100%;

    &.active {
        cursor: pointer;

        &:hover,
        &:focus {
            background: $gradient2-dark;
        }
    }
}

@mixin input-gray() {
    display: block;
    border: none;
    outline: none;
    margin-left: math.div($margin, 1.5);
    padding: $margin * 0.5;
    font-size: $small;
    background-color: $gray;
    border-radius: $border-radius;
}

@mixin button-primary() {
    background-image: linear-gradient(30deg, #d6116d 10%, #ff7171);
    border: none;
    color: white;

    &:hover:not(:disabled) {
        background-image: linear-gradient(30deg, darken(#d6116d, 5%) 10%, darken(#ff7171, 5%));
    }
}

@mixin button-global() {
    border-radius: $border-radius;
    border-radius: 5px;
    border: 1px solid $gray-dark;
    box-sizing: border-box;
    color: $black;
    height: 30px;
    outline: none;
    padding: $margin * 0.25 $margin * 0.5;
    text-transform: uppercase;
    transition: background-image 0.3s ease-in;
    background-image: linear-gradient(to bottom, lighten($gray, 1%), darken($gray, 1%));

    &:hover:not(:disabled) {
        background-image: linear-gradient(to bottom, darken($gray, 1%), darken($gray, 2%));
    }
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }

    img {
        height: 15px;
        width: 15px;
    }
}

@mixin noSelect() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin cmsInput() {
    background-color: #ffffff;
    border-radius: $border-radius;
    border: 1px solid $gray-dark;
    box-sizing: border-box;
    color: #000000;
    font-family: $font;
    height: 30px;
    letter-spacing: -1.03px;
    outline: none;
    padding: $margin * 0.25 $margin * 0.5;

    &::placeholder {
        font-family: $font;
        letter-spacing: -1.03px;
        color: #8c82ad;
    }

    &:disabled {
        background-color: $gray;
    }
}

@mixin flatSelect() {
    height: 40px !important;
    box-shadow: none !important;
    border: 1px solid #dddddd !important;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.86px;
    color: #000000;
    width: 100%;
}

@mixin h1() {
    font-size: 35px;
    letter-spacing: -2px;
    color: $flat-purple;
    margin: 0;
    font-weight: 300;
}

.deployment {
    @include panel();
    $border-radius: 7px;
    align-items: flex-start;
    background-color: #f2f2f2;
    border-radius: $border-radius;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    padding: 0;
    overflow: hidden;

    .title {
        width: 100%;
        box-sizing: border-box;
        height: 61px;
        background: $gradient2;
        display: flex;
        align-items: center;
        font-size: 22px;
        letter-spacing: -1.26px;
        color: #ffffff;
        padding-left: 220px;

        > span {
            font-weight: 500;
            margin-right: 5px;
        }

        .testsStatus {
            float: left;
        }

        .date {
            font-size: 16px;
            position: absolute;
            right: 48px;
        }
    }

    .details {
        box-sizing: border-box;
        display: grid;
        gap: 20px;
        grid-template-columns: 180px 1fr;
        padding: 0 math.div($margin, 3) * 2;
        position: relative;
        width: 100%;

        .actions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            top: -42px;
            width: 100%;

            .imageWrapper {
                align-self: center;
                background: white;
                border-radius: 5px;
                border: 1px solid #dddddd;
                box-sizing: border-box;
                display: flex;
                height: 120px;
                justify-content: center;
                padding: 20px;
                width: 100%;

                img {
                    width: 100px;
                    height: auto;
                    object-fit: contain;
                }
            }

            button {
                margin-top: 10px !important;
                width: 100% !important;
            }
        }

        .data {
            position: relative;
            width: 100%;
            font-size: 16px;
            line-height: 1.25;
            letter-spacing: -0.5px;
            color: #909090;

            > div:not(.absoluteLoader) {
                padding: $margin * 0.5 0;

                span {
                    font-weight: 500;
                    color: #000000;
                }
            }

            > div {
                display: flex;
                align-items: center;

                span {
                    margin-right: 8px;
                }
            }

            > div:not(:last-child) {
                border-bottom: 1px solid #dddddd;
            }

            .crmLogo {
                display: inline-block;
                margin-left: 2px;
                height: 22px;

                .crmLogoContainer {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    img {
                        height: 100%;
                    }

                    .text {
                        margin-left: 5px;
                        font-size: $tiny;
                    }
                }
            }
        }
    }

    .cardFooter {
        background-color: #fff;
        border-top: 1px solid #dadada;
        width: 100%;
        height: 110px;
        padding: math.div($margin, 3);
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;

        & > * {
            width: 100%;

            button {
                width: 100%;
            }
        }

        select {
            grid-column: 1 / 3;
        }
    }

    .absoluteLoader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    button {
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.active {
    background: $gradient;
    color: rgba(255, 255, 255, 0.15);

    .details {
        .data {
            > div:not(.absoluteLoader) {
                span {
                    font-weight: 500;
                    color: rgba(255, 255, 255, 0.7);
                }
            }

            > div:not(:last-child) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.7);
            }
        }
    }
}

.button {
    margin-top: $margin * 0.25;

    a {
        color: $black;
    }
}
